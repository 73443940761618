h3 {
  margin-top: 1em;
}

.rs-nav {
  border-radius: 0.5em;
  background-color: rgba(0, 0, 0, 0.5);
}

.rs-nav-item.rs-nav-item-active>.rs-nav-item-content {
  background-color: #e5e5ea;
}
